import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Usuario from '../../images/default-user.png';

import { FaBars } from 'react-icons/fa';

import './Header.css';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            usuario: null
        };
    }

    componentDidMount() {
        this.setState({ usuario: localStorage.getItem('user_name') })
    }

    cerrarSesion = () => {
        localStorage.clear();
        this.setState({ usuario: null });
        this.props.history.push('/login');
    }

    mostrarModalUsuario = () => {
        
    }

    render() {
        return(
            <header id="page-header">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <button type="button" className="btn btn-sm btn-dual mr-2 d-lg-none" data-toggle="layout" data-action="sidebar_toggle">
                            <FaBars />
                        </button>
                        <button type="button" className="btn btn-sm btn-dual mr-2 d-none d-lg-inline-block" data-toggle="layout" data-action="sidebar_mini_toggle">
                            <FaBars />
                        </button>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="dropdown d-inline-block ml-2">
                            <button type="button" className="btn btn-sm btn-dual" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="rounded" src={Usuario} alt="Avatar" style={{width: '18px'}} />
                                <span className="d-none d-sm-inline-block ml-1">{this.state.usuario}</span>
                                <i className="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm" aria-labelledby="page-header-user-dropdown" x-placement="bottom-end" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(104px, 31px, 0px)'}}>
                                <div className="p-3 text-center bg-primary">
                                    <img className="img-avatar img-avatar48 img-avatar-thumb" src={Usuario} alt="" />
                                </div>
                                <div className="p-2">
                                    <h5 className="dropdown-header text-uppercase">Opciones</h5>
                                    <Link className="dropdown-item d-flex align-items-center justify-content-between" to="/misdatos"><span>Mis Datos</span></Link>
                                    <div role="separator" className="dropdown-divider"></div>
                                    <h5 className="dropdown-header text-uppercase">Acciones</h5>
                                    <button type="button" className="dropdown-item d-flex align-items-center justify-content-between" onClick={this.cerrarSesion}>
                                        <span>Salir</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);