import React from 'react';
import axios from 'axios';

import { FaSearch } from 'react-icons/fa';
import Resultado from './../Resultado';


class BuscadorCaleu extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            cardcode: '',
            fecha_desde: this.getCurrentDate(-1),
            fecha_hasta: this.getCurrentDate(),
            tipo_documento: 0,
            usuarios: [],
            loadingForm: true,
            documentos: [],

            usuariosVisible: [],
            filtroCliente: ''
        }
    }

    async componentDidMount() {
        
        const url = `${process.env.REACT_APP_API_URL}/api/clientes/usuarios`;
        const response = await axios.get(url);

        this.setState({
            usuarios: response.data,
            usuariosVisible: response.data.slice(0, 10),
            loadingForm: false
        })
    }

    getCurrentDate = (addMonth = 0) => {
        
        let newDate = new Date();
        let date = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
        let month = newDate.getMonth() + 1 + (addMonth);
        let year = newDate.getFullYear();        
        let separator = '-';
        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({ [event.target.name]: value });
    }
    /*
    filterTable = async (event) => {
        const value = event.target.value;
        const valueSearch = value.length > 0 ? value.toUpperCase(): '';        
        this.setState({ filtroCliente: value });
    }*/

    handlePress = async (event) => {
        const value = event.target.value;        
        const valueSearch = value.length > 0 ? value.toUpperCase(): '';        
        this.setState({ filtroCliente: value });
        if (event.key === 'Enter') {
            let filtrados = [];
            if (value.length === 0) {
                filtrados = this.state.usuarios.slice(0, 10);
                this.setState({ usuariosVisible: filtrados });
                return;
            }
            const total = this.state.usuarios.length; 
            for (let i = 0; i < total; i++) {   
                if (this.state.usuarios[i].codigoadministrador !== null) {      
                    if (
                        this.state.usuarios[i].codigoadministrador.includes(valueSearch) ||
                        this.state.usuarios[i].cardcode.includes(valueSearch) ||
                        this.state.usuarios[i].administrador.includes(valueSearch)
                    ) {
                        filtrados.push(this.state.usuarios[i]);
                    }
                }
            }
            this.setState({ usuariosVisible: filtrados }); 
        }
    }
    
    handleSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ disableForm: true });            
            const url = `${process.env.REACT_APP_API_URL}/api/documento/${this.state.cardcode}/${this.state.fecha_desde}/${this.state.fecha_hasta}`;
            const response = await axios.get(url);
            /*const docFiltrados = response.data.filter((doc) => {
                return doc.indicator == this.state.tipo_documento;
            });*/
            //console.log(response.data);
            this.setState({ documentos: response.data });            
        } catch (e) {
            console.error(e);
            this.setState({ documentos: [] });
        }
        finally {
            this.setState({ disableForm: false });
        }

    }

    onChangeCliente = (event) => {
        this.setState({
            cardcode: event.target.value
        });
    }

    render() {
        const disabled = false;

        return(
            <>
                {this.state.loadingForm ? ('Cargando...') : 
                (
                <>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-row align-items-center">
                        <div className="col-sm-4 my-1">
                            <label>Desde</label>
                            <input type="date" className="form-control form-control-sm my-1 mr-sm-2" name="fecha_desde" onChange={this.handleChange} defaultValue={this.state.fecha_desde} disabled={disabled} />
                        </div>
                        <div className="col-sm-4 my-1">
                            <label>Hasta</label>
                            <input type="date" className="form-control form-control-sm my-1 mr-sm-2" name="fecha_hasta" onChange={this.handleChange} defaultValue={this.state.fecha_hasta} disabled={disabled} />
                        </div>
                        <div className="col-sm-4 my-1">
                            <label>Documento</label>
                            <select defaultValue="" className="form-control form-control-sm my-1 mr-sm-2" name="tipo_documento" onChange={this.handleChange} defaultValue={this.state.tipo_documento} disabled={disabled}>
                                <option value="33">Facturas</option>
                                <option value="52">Guías de Despacho</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 text-right">
                            <input type="text" className="form-control" name="filtroCliente" placeholder="Filtrar..." onKeyPress={this.handlePress} onChange={this.handleChange} value={this.state.filtroCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-sm table-striped mt-2">
                                <thead>
                                    <tr>
                                        <th>Administración</th>
                                        <th>Código</th>
                                        <th>Comunidad</th>
                                        <th>Código</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.usuariosVisible.length > 0 ? 
                                    (this.state.usuariosVisible.map((usuario, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{usuario.administrador}</td>
                                                <td>{usuario.codigoadministrador}</td>
                                                <td>{usuario.cardname}</td>
                                                <td>{usuario.cardcode}</td>
                                                <td><input type="radio" name="cardcode" value={usuario.cardcode} onChange={this.onChangeCliente} /></td>
                                            </tr>);
                                    }))
                                    :
                                    (
                                        <tr><td colSpan={5} className="text-center">No hay coincidencias</td></tr>
                                    )}
                                </tbody>
                            </table>
                        </div>                        
                    </div>      
                    <div className="row">
                        <div className="col-md-offset-6 col-md-3">
                            <button type="button" className="btn btn-sm btn-block btn-primary" style={{marginTop: '1.6rem'}} onClick={this.handleSubmit} disabled={disabled}><FaSearch /> Buscar</button>
                        </div>                        
                    </div>             
                    
                </form>
                <Resultado docs={this.state.documentos} />
                </>
                )}
            </>
        );
    }

}

export default BuscadorCaleu;