import React from 'react';
import axios from 'axios';
import Noty from 'noty';
import { Link } from 'react-router-dom';

import { FaUsers, FaUserPlus, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';



class ListaUsuarios extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            usuario: {},
            usuarios: [],
            showModal: false
        }
        
        this.openModalHandler = this.openModalHandler.bind(this);
    }

    async loadUsers() {
        const usuariosData = (await axios.get(`${process.env.REACT_APP_API_URL}/api/usuario/`)).data;
        const usuarios = usuariosData.map((u) => {
            u.estado = u.activo ? 'Activo' : 'Bloqueado';
            return u;
        });
        this.setState({ usuarios });
    }

    async componentDidMount() {
        await this.loadUsers();
    }

    openModalHandler = async (e) => {
        if (e.target.value !== undefined) {
            const usuario = (await axios.get(`${process.env.REACT_APP_API_URL}/api/usuario/${e.target.value}`)).data;
            this.setState({ usuario });
        }
        this.setState({ showModal: true });
    }

    closeModalHandler = () => {
        const usuario = {};
        this.setState({ usuario, showModal: false });
    }

    confirmRemove = async (id) => {
        var r = window.confirm("Estas seguro que deseas eliminar este usuario?");
        if (r === true) {
            const deleteUsuario = (await axios.delete(`${process.env.REACT_APP_API_URL}/api/usuario/${id}`));
            await this.loadUsers();
            new Noty({
                text: deleteUsuario.data.message,
                layout: 'top',
                theme: 'bootstrap-v4',
                type: 'success',
                timeout: 4000
            }).show();
        }
    }

    render() {
        if (this.state.usuarios.length > 0) {
            return(
                <div>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pb-2 mb-3">
                        <h4 className="h4"><FaUsers/> Usuarios</h4>
                    </div>                
                    <div className="card">
                        <div className="card-body">
                            <Link to="/usuarios/crear" className="btn btn-sm btn-primary"><FaUserPlus/> Nuevo Usuario</Link>
                            <div className="table-responsive mt-3">
                                <table id="tbl-users" className="table table-sm table-vcenter">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Nombre</th>
                                            <th>Perfil</th>
                                            <th>Empresa</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.usuarios.map((u, index) => 
                                            <tr key={u.id}>
                                                <td>{index + 1}</td>
                                                <td>{u.email}</td>
                                                <td>{u.nombres} {u.apellido_paterno}</td>
                                                <td>{u.perfil}</td>
                                                <td>{u.empresa}</td>
                                                <td>{u.estado}</td>
                                                <td className="text-right">
                                                    <button onClick={()=>{this.props.history.push(`/usuarios/ficha:${u.id}`);}} type="button" className="btn btn-sm btn-secondary"><FaPencilAlt /> Editar</button> { ' ' }
                                                    <button onClick={() => this.confirmRemove(u.id)}  type="button" className="btn btn-sm btn-secondary"><FaTrashAlt /> Eliminar</button>
                                                </td>    
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(<div>Cargando...</div>);
        }
    }
}

export default ListaUsuarios;