import React from 'react';

import Noty from 'noty';
import axios from 'axios';
import { FaFileAlt, FaSync } from 'react-icons/fa';

import Buscador from './Buscador';


class Explorador extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fechaUltimaSync: null
        }
    }

    dataHandler = (data) => {
        

        // mensaje
        const tipo = data.length > 0 ? 'info' : 'warning';
        const mensaje = data.length > 0 ? `Se han encontrado <b>${data.length}</b> documentos` : 'No se han encontrado documentos en el periodo consultado';

        new Noty({
            text: mensaje,
            layout: 'top',
            theme: 'bootstrap-v4',
            type: tipo,
            timeout: 4000
        }).show();
    };

    async componentDidMount() {
        const fechaSync = (await axios.get(`${process.env.REACT_APP_API_URL}/api/utils/sincronizacion`)).data;
        this.setState({ fechaUltimaSync: fechaSync.fecha_actualiza });
    }

    render() {

        return(
            <div>
                <div className="flex-md-nowrap mt-3 pb-2 mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="h4"><FaFileAlt /> Documentos</h4>
                        </div>
                        <div className="col-md-6">
                            <p className="text-right"><strong><FaSync /> Última Sincronización:</strong> {this.state.fechaUltimaSync} </p>
                        </div>
                    </div>
                </div>                
                <div className="card">
                    <div className="card-body">
                        <Buscador />                        
                    </div>
                </div>
            </div>
        );
    }
}

export default Explorador;