import React from 'react';
import axios from 'axios';
import { getJwt } from '../helpers/jwt';
import { withRouter } from 'react-router-dom';

class Authenticated extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null
        };

        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('user_token')}`};
        axios.defaults.headers.post = { 'Content-Type': 'application/x-www-form-urlencoded' };
        axios.defaults.headers.get = { 'Content-Type': 'application/x-www-form-urlencoded' };
    }

    componentDidMount() {
        const jwt = getJwt();
        if (!jwt) {
            this.props.history.push('/Login');
        } else {
            this.setState({ user: localStorage.getItem('user_name') });
        }
    }

    render() {

        if (this.state.user === null) {
            return (
                <div><p>Cargando...</p></div>
            );
        }

        return(
            <div>
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(Authenticated);