import React from 'react';

import BuscadorCaleu from './Explorador/BuscadorCaleu';
import BuscadorKastor from './Explorador/BuscadorKastor';

class Buscador extends React.Component {

    render() {        
        return(
            <div>
                {localStorage.getItem('user_emp') === 'CALEU' ? (<BuscadorCaleu />):(<BuscadorKastor />)}
            </div>
        );
    }
}

export default Buscador;