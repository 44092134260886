import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Authenticated from './Authenticated';

import Login from './Login';
import Recovery from './Recovery';
import Explorador from './Explorador';
import Usuarios from './Usuarios/ListaUsuarios';
import FichaUsuario from './Usuarios/FichaUsuario';
import MisDatos from './Usuarios/MisDatos';

import './App.css';
import "../../node_modules/noty/lib/noty.css";  
import '../../node_modules/noty/lib/themes/bootstrap-v4.css'; 

class App extends React.Component {
    render() {
        return(
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/recovery" component={Recovery} />
                    <Authenticated>
                        <div id="page-container" className="sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed side-trans-enabled">
                            <Sidebar />
                            <Header />
                            <main id="main-container">
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Route exact path='/' component={Explorador} />
                                            <Route exact path='/usuarios' component={Usuarios} />
                                            <Route exact path='/usuarios/crear' component={FichaUsuario} />
                                            <Route exact path='/usuarios/ficha:id' component={FichaUsuario} />   
                                            <Route exact path='/misdatos' component={MisDatos} />                                         
                                        </div>
                                    </div>       
                                </div>                             
                            </main>
                        </div>
                    </Authenticated>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;