import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import Noty from 'noty';
import { FaSearch } from 'react-icons/fa';
import Resultado from './../Resultado';

class BuscadorKastor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            comunidad_id: '',
            perfil_id: 0,
            fecha_desde: this.getCurrentDate(-1),
            fecha_hasta: this.getCurrentDate(),
            tipo_documento: "33",

            comunidades: [],
            documentos: [],

            selectedOption: null,
            disableForm: false
        }
    }

    getCurrentDate = (addMonth = 0) => {
        
        let newDate = new Date();
        let date = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
        let month = newDate.getMonth() + 1 + (addMonth);
        let year = newDate.getFullYear();        
        let separator = '-';
        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({ [event.target.name]: value });
    } 

    handleChangeComunidad = (selectedOption) => {
        this.setState({ selectedOption });
    }

    async componentDidMount() {
        try {
            
            const url = `${process.env.REACT_APP_API_URL}/api/comunidad/`;
            const response = await axios.get(url);
            let comunidades = response.data.map((d) => {
                return { value: d.cardcode, label: d.cardcode + ' - ' + d.cardname };
            });
            comunidades.push({ value: 'all', label: '- TODAS -' })            

            this.setState({ comunidades });
        }
        catch (e) {
            this.setState({ comunidades: [] });
        }
        finally {

        }        
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.selectedOption === null) {
            new Noty({
                text: 'Debes seleccionar una comunidad para consultar',
                layout: 'top',
                theme: 'bootstrap-v4',
                type: 'error',
                timeout: 5000
            }).show();

        } else {
            
            try {
                this.setState({ disableForm: true });            
                const url = `${process.env.REACT_APP_API_URL}/api/documento/${this.state.selectedOption.value}/${this.state.fecha_desde}/${this.state.fecha_hasta}`;
                const response = await axios.get(url);
                console.log(response.data);
                const docFiltrados = response.data.filter((doc) => {
                    return doc.indicator === this.state.tipo_documento;
                });
                console.log(docFiltrados);
                this.setState({ documentos: docFiltrados });            
            } catch (e) {
                this.setState({ documentos: [] });
            }
            finally {
                this.setState({ disableForm: false });
            }
        }
    }

    render() {
        const disabled = this.state.disableForm;
        return(
            <>
            <form className="form-row align-items-center" onSubmit={this.handleSubmit}>
                <div className="col-sm-5 my-1">
                    <label htmlFor="comunidad_id">Comunidad</label>
                    <Select 
                        classes="form-control" 
                        options={this.state.comunidades}
                        value={this.state.selectedOption}
                        onChange={this.handleChangeComunidad}
                        placeholder="Buscar Comunidad..."
                    />
                </div>
                <div className="col-sm-2 my-1">
                    <label>Desde</label>
                    <input type="date" className="form-control form-control-sm my-1 mr-sm-2" name="fecha_desde" onChange={this.handleChange} defaultValue={this.state.fecha_desde} disabled={disabled} />
                </div>
                <div className="col-sm-2 my-1">
                    <label>Hasta</label>
                    <input type="date" className="form-control form-control-sm my-1 mr-sm-2" name="fecha_hasta" onChange={this.handleChange} defaultValue={this.state.fecha_hasta} disabled={disabled} />
                </div>
                <div className="col-sm-2 my-1">
                    <label>Documento</label>
                    <select defaultValue="" className="form-control form-control-sm my-1 mr-sm-2" name="tipo_documento" onChange={this.handleChange} defaultValue={this.state.tipo_documento} disabled={disabled}>
                        <option value="33">Facturas Afectas</option>
                        <option value="34">Facturas Exentas</option>
                        <option value="61">Notas de Crédito</option>
                    </select>
                </div>
                <div className="col-sm-1 my-1">
                    <button type="submit" className="btn btn-sm btn-primary" style={{marginTop: '1.6rem'}} onClick={this.handleSubmit} disabled={disabled}><FaSearch /> Buscar</button>
                </div>
            </form>
            <Resultado docs={this.state.documentos} />
            </>
        );
    }

}

export default BuscadorKastor;