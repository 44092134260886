import React from 'react';
import axios from 'axios';
import Noty from 'noty';
import { withRouter } from 'react-router-dom';
import { FaUser, FaUserPlus } from 'react-icons/fa';

class MisDatos extends React.Component {
 
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            nombres: '',
            apellido_paterno: '',
            apellido_materno: '',
            email: '',
            empresa: '',
            perfil_id: 0,
            activo: true,

            password1: '',
            password2: '',

            perfiles: [],
            empresas: [],
            isLoading: true
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox'? event.target.checked: event.target.value;
        this.setState({ [event.target.name]: value });
    }

    handleEmpresa = (option) => {
        this.setState({ empresa: option.value  });
    }

    handlePerfil = (option) => {
        this.setState({ perfil_id: option.value });
    }

    handleSubmit = async (event) => {

        event.preventDefault();

        let tipo = '';
        let mensaje = '';

        try {

            var params = new URLSearchParams();
            params.append('id', this.state.id);
            params.append('nombres', this.state.nombres);
            params.append('apellido_paterno', this.state.apellido_paterno);
            params.append('apellido_materno', this.state.apellido_materno);
            params.append('email', this.state.email);
            params.append('perfil_id', this.state.perfil_id);
            params.append('empresa', this.state.empresa);
            params.append('activo', this.state.activo);

            const pass1 = this.state.password1;
            const pass2 = this.state.password2

            if (pass1.length > 0 || pass2.length > 0)
            {
                if (pass1 !== pass2) {
                    throw new Error('El password ingresado no coincide');
                } else {
                    params.append('password', pass1);
                }
            }

            let response = {};
            response = (await axios.put(`${process.env.REACT_APP_API_URL}/api/usuario/`, params));  
            
            tipo = 'success';
            mensaje = response.data.message;   
        }
        catch (e)
        {
            console.log(e);
            mensaje = e.message;
            tipo = 'error';
        }

        new Noty({
            text: mensaje,
            layout: 'top',
            theme: 'bootstrap-v4',
            type: tipo,
            timeout: 4000
        }).show();        
    }

    async componentDidMount() {

        const perfiles = (await axios.get(`${process.env.REACT_APP_API_URL}/api/perfil/`)).data;
        const perfilesData = perfiles.map((perfil) => {
            return { label: perfil.nombre, value: perfil.id }
        });

        const empresasData = [
            { label: 'CALEU', value: 'CALEU' },
            { label: 'KASTOR', value: 'KASTOR' }
        ];

        const usuario = await axios.get(`${process.env.REACT_APP_API_URL}/api/usuario/${localStorage.getItem('user_id')}`);
        this.setState({
            id: usuario.data.id,
            nombres: usuario.data.nombres,
            apellido_paterno: usuario.data.apellido_paterno,
            apellido_materno: usuario.data.apellido_materno,
            email: usuario.data.email,
            empresa: usuario.data.empresa,
            perfil_id: usuario.data.perfil_id,
            activo: usuario.data.activo
        });        

        this.setState({ 
            perfiles: perfilesData,
            empresas: empresasData,     
            isLoading: false
        });
    }

    obtenerPerfil = (id) => {
        const perfil = this.state.perfiles.filter((per) => per.value === id);
        return perfil.length > 0 ? perfil[0].label : 'Seleccione...';
    };

    render() {
        return(
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pb-2 mb-3">
                    <h4 className="h4"><FaUser/> Mis Datos</h4>
                </div>                
                <div className="card">
                    <div className="card-body">
                        {this.state.isLoading ? (' Cargando ... ')
                        :
                        (<>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label>Nombres</label>
                                <input type="text" className="form-control form-control-sm" name="nombres" placeholder="Nombres" value={this.state.nombres} readOnly={true} />
                            </div>
                            <div className="form-group">
                                <label>Apellido Paterno</label>
                                <input type="text" className="form-control form-control-sm" name="apellido_paterno" placeholder="Apellido Paterno" value={this.state.apellido_paterno} readOnly={true} />
                            </div>
                            <div className="form-group">
                                <label>Apellido Materno</label>
                                <input type="text" className="form-control form-control-sm" name="apellido_materno" placeholder="Apellido Materno" value={this.state.apellido_materno} readOnly={true} />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control form-control-sm" name="email" placeholder="Correo Electrónico" value={this.state.email} readOnly={true} />
                            </div>
                            <br />
                            <div className="form-group">
                                <label>Nueva Contraseña</label>
                                <input type="password" className="form-control form-control-sm" name="password1" placeholder="" value={this.state.password1} onChange={this.handleChange}  />
                            </div>
                            <div className="form-group">
                                <label>Confirmar Contraseña</label>
                                <input type="password" className="form-control form-control-sm" name="password2" placeholder="" value={this.state.password2} onChange={this.handleChange}  />
                            </div>
                        </form>
                        <button type="button" className="btn btn-sm btn-success" onClick={this.handleSubmit}><FaUserPlus /> Guardar</button> {' '}
                        <button type="button" className="btn btn-sm btn-secondary" onClick={() =>{ this.props.history.goBack(); }}>Atrás</button>
                        </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MisDatos);