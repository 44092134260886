import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/kastor-logo.png';
import { FaUsers, FaFileAlt } from 'react-icons/fa';

import './Sidebar.css';

class Sidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            perfil_id: 0,
            isLoading: true
        }
    }

    componentDidMount() {
        const perfilId = parseInt(localStorage.getItem('per'));
        this.setState({
            perfil_id: perfilId,
            isLoading: false
        })
    }

    render() {
        return(
            <nav id="sidebar" aria-label="Main Navigation" data-simplebar="init">
	            <div className="simplebar-scroll-content" style={{paddingRight: '17px', marginBottom: '-34px'}}>
		            <div className="simplebar-content" style={{paddingBottom: '17px', marginRight: '-17px'}}>
			            <div className="content-header bg-white-5">
                            <img src={Logo} style={{ width: '120px', margin: '0 auto' }} alt="Kastor" />				
			            </div>
                        {this.state.isLoading ? 
                        (
                            <div>...</div>
                        ):
                        (
                            <div className="content-side content-side-full">
                                <ul className="nav-main">
                                    <li className="nav-main-item">
                                        <Link className="nav-main-link" to="/"><FaFileAlt className="nav-main-link-icon" /> <span className="nav-main-link-name">Documentos</span></Link>
                                    </li>
                                    {this.state.perfil_id === 1 ? 
                                    (
                                        <li className="nav-main-item">
                                            <Link className="nav-main-link" to="/usuarios"><FaUsers className="nav-main-link-icon" /> <span className="nav-main-link-name">Usuarios</span></Link>
                                        </li>
                                    ):
                                    (' ')}                                    
                                </ul>
                            </div>
                        )}                        
                    </div>
                </div>
            </nav>            
        );
    }
}



export default Sidebar;