import React from 'react';
import axios from 'axios';
import Noty from 'noty';

import { FaSignInAlt } from 'react-icons/fa';
import Logo from '../images/kastor-logo-back.png';
import './Login.css';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            usuario: '',
            password: '',
            loggedin: false,
            disableForm: false
        };
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({ [event.target.name]: value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();     

        if (this.state.usuario.length === 0 || this.state.password.length === 0) {
            new Noty({
                text: 'Debes ingresar tu usuario y contraseña para acceder',
                layout: 'top',
                theme: 'bootstrap-v4',
                type: 'error',
                timeout: 5000
            }).show();
            return;
        }

        try {
            this.setState({ disableForm: true });

            var params = new URLSearchParams();
            params.append('usuario', this.state.usuario);
            params.append('password', this.state.password);
            
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/`, params);
            if (response.status === 200) {                
                localStorage.setItem('user_token', response.data.token);
                localStorage.setItem('user_name', response.data.nombre);        
                localStorage.setItem('user_emp', response.data.empresa);    
                localStorage.setItem('user_id', response.data.id);
                localStorage.setItem('per', response.data.per);
                this.setState({ loggedin: true });
                this.props.history.push('/');
            }
        } catch (e) {
            new Noty({
                text: 'Usuario y/o contraseña incorrecta',
                layout: 'top',
                theme: 'bootstrap-v4',
                type: 'error',
                timeout: 5000
            }).show();
        }
        finally {
            this.setState({ disableForm: false });
        }
    }

    render() {

        const disabled = this.state.disableForm ? true : false;

        return(
            <div id="page-container">
                <main id="main-container">
                    <div className="hero-static d-flex align-items-center">
                        <div className="w-100">
                            <div className="content content-full bg-white">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col-lg-6 col-xl-4 py-4">
                                        <div className="text-center">
                                            <img src={Logo} style={{ width: '170px', margin: '0 auto' }} alt="Kastor" />
                                            <h1 className="h4 mb-4 mt-4">
                                                Explorador de Documentos Kastor
                                            </h1>
                                        </div>
                                        <form className="js-validation-signin" onSubmit={this.handleSubmit}>
                                            <div className="py-3">
                                                <div className="form-group">
                                                    <input type="text" className="form-control form-control-lg" value={this.state.usuario} onChange={this.handleChange} name="usuario" placeholder="Usuario" disabled={disabled} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-lg" value={this.state.password} onChange={this.handleChange} name="password" placeholder="Contraseña" disabled={disabled} />
                                                </div>
                                                <div className="form-group">
                                                    <div className="py-2">
                                                        <a className="font-size-sm" href="/recovery">Recuperar Contraseña</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row justify-content-center mb-0">
                                                <div className="col-md-6 col-xl-5">
                                                    <button type="button" onClick={this.handleSubmit} className="btn btn-block btn-primary" disabled={disabled}>
                                                        <FaSignInAlt /> Acceder
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="font-size-sm text-center text-muted py-3">
                                <strong>Kastor File Explorer</strong> &copy; <span data-toggle="year-copy"></span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default Login;