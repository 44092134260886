import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import Noty from 'noty';
import { withRouter } from 'react-router-dom';
import { FaUsers, FaUserPlus } from 'react-icons/fa';

class FichaUsuario extends React.Component {
 
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            nombres: '',
            apellido_paterno: '',
            apellido_materno: '',
            email: '',
            empresa: '',
            perfil_id: 0,
            activo: true,

            perfiles: [],
            empresas: [],
            isLoading: true
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox'? event.target.checked: event.target.value;
        this.setState({ [event.target.name]: value });
    }

    handleEmpresa = (option) => {
        this.setState({ empresa: option.value  });
    }

    handlePerfil = (option) => {
        this.setState({ perfil_id: option.value });
    }

    handleSubmit = async (event) => {

        event.preventDefault();

        let tipo = '';
        let mensaje = '';

        try {
            var params = new URLSearchParams();
            params.append('id', this.state.id);
            params.append('nombres', this.state.nombres);
            params.append('apellido_paterno', this.state.apellido_paterno);
            params.append('apellido_materno', this.state.apellido_materno);
            params.append('email', this.state.email);
            params.append('perfil_id', this.state.perfil_id);
            params.append('empresa', this.state.empresa);
            params.append('activo', this.state.activo);

            let response = {};
            if (this.state.id === 0) {
                response = (await axios.post(`${process.env.REACT_APP_API_URL}/api/usuario/`, params));            
            } else {
                response = (await axios.put(`${process.env.REACT_APP_API_URL}/api/usuario/`, params));            
            }
            
            tipo = 'success';
            mensaje = response.data.message;   
        }
        catch (e)
        {
            mensaje = e.message;
            tipo = 'error';
        }

        new Noty({
            text: mensaje,
            layout: 'top',
            theme: 'bootstrap-v4',
            type: tipo,
            timeout: 4000
        }).show();        
    }

    async componentDidMount() {

        const perfiles = (await axios.get(`${process.env.REACT_APP_API_URL}/api/perfil/`)).data;
        const perfilesData = perfiles.map((perfil) => {
            return { label: perfil.nombre, value: perfil.id }
        });

        const empresasData = [
            { label: 'CALEU', value: 'CALEU' },
            { label: 'KASTOR', value: 'KASTOR' }
        ];

        const { id } = this.props.match.params
        if (id !== undefined) {
            const usuario = await axios.get(`${process.env.REACT_APP_API_URL}/api/usuario/${id.replace(':', '')}`);
            this.setState({
                id: usuario.data.id,
                nombres: usuario.data.nombres,
                apellido_paterno: usuario.data.apellido_paterno,
                apellido_materno: usuario.data.apellido_materno,
                email: usuario.data.email,
                empresa: usuario.data.empresa,
                perfil_id: usuario.data.perfil_id,
                activo: usuario.data.activo
            });
        }

        this.setState({ 
            perfiles: perfilesData,
            empresas: empresasData,     
            isLoading: false
        });
    }

    obtenerPerfil = (id) => {
        const perfil = this.state.perfiles.filter((per) => per.value === id);
        return perfil.length > 0 ? perfil[0].label : 'Seleccione...';
    };

    render() {
        return(
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pb-2 mb-3">
                    <h4 className="h4"><FaUsers/> Ficha Usuario</h4>
                </div>                
                <div className="card">
                    <div className="card-body">
                        {this.state.isLoading ? (' ')
                        :
                        (<>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label>Nombres</label>
                                <input type="text" className="form-control form-control-sm" name="nombres" placeholder="Nombres" value={this.state.nombres} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Apellido Paterno</label>
                                <input type="text" className="form-control form-control-sm" name="apellido_paterno" placeholder="Apellido Paterno" value={this.state.apellido_paterno} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Apellido Materno</label>
                                <input type="text" className="form-control form-control-sm" name="apellido_materno" placeholder="Apellido Materno" value={this.state.apellido_materno} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control form-control-sm" name="email" placeholder="Correo Electrónico" value={this.state.email} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Perfil</label>
                                <Select 
                                    placeholder="Selecciona..."
                                    options={this.state.perfiles}  
                                    onChange={this.handlePerfil} 
                                    value={{ 
                                        label: this.obtenerPerfil(this.state.perfil_id),
                                        value: this.state.perfil_id
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Empresa</label>                                
                                <Select 
                                    options={this.state.empresas} 
                                    onChange={this.handleEmpresa} 
                                    value={{ label: this.state.empresa, value: this.state.empresa }} 
                                    placeholder="Selecciona..."
                                />
                            </div>
                        </form>
                        <button type="button" className="btn btn-sm btn-success" onClick={this.handleSubmit}><FaUserPlus /> Guardar</button> {' '}
                        <button type="button" className="btn btn-sm btn-secondary" onClick={() =>{ this.props.history.goBack(); }}>Atrás</button>
                        </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FichaUsuario);