const TiposDocumento = (codigo) => {
    const tipos = [
        { codigo:30, descripcion: 'Factura' },
        { codigo:32, descripcion: 'Factura de ventas y servicios no afectos o exentos de IVA' },
        { codigo:33, descripcion: 'Factura electrónica' },
        { codigo:34, descripcion: 'Factura no afecta o exenta electrónica' },
        { codigo:35, descripcion: 'Boleta' },
        { codigo:38, descripcion: 'Boleta exenta' },
        { codigo:39, descripcion: 'Boleta electrónica' },
        { codigo:40, descripcion: 'Liquidación factura' },
        { codigo:41, descripcion: 'Boleta exenta electrónica' },
        { codigo:43, descripcion: 'Liquidación factura electrónica' },
        { codigo:45, descripcion: 'Factura de compra' },
        { codigo:46, descripcion: 'Factura de compra electrónica' },
        { codigo:48, descripcion: 'Pago electrónico' },
        { codigo:50, descripcion: 'Guía de despacho' },
        { codigo:52, descripcion: 'Guía de despacho electrónica' },
        { codigo:55, descripcion: 'Nota de débito' },
        { codigo:56, descripcion: 'Nota de débito electrónica' },
        { codigo:60, descripcion: 'Nota de crédito' },
        { codigo:61, descripcion: 'Nota de crédito electrónica' },
        { codigo:103, descripcion: 'Liquidación' },
        { codigo:110, descripcion: 'Factura de exportación electrónica' },
        { codigo:111, descripcion: 'Nota de débito de exportación electrónica' },
        { codigo:112, descripcion: 'Nota de crédito de exportación electrónica' }
    ];

    const tipoDocumento = tipos.filter((tipo) => {
        return tipo.codigo === codigo;
    });

    return tipoDocumento[0];
};

export default TiposDocumento;