import React from 'react';
import axios from 'axios';
import Noty from 'noty';

import { FaEnvelope } from 'react-icons/fa';
import Logo from '../images/kastor-logo-back.png';
import './Login.css';

class Recovery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            usuario: ''
        };
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({ [event.target.name]: value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();        

        try {

            // validar correo 
            if (!this.state.usuario.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
                throw new Error("El correo ingresado es inválido"); 
                

            if (this.state.usuario.length === 0) {
                new Noty({
                    text: 'Debes ingresar un usuario válido',
                    layout: 'top',
                    theme: 'bootstrap-v4',
                    type: 'error',
                    timeout: 5000
                }).show();
                return;
            }
       
            this.setState({ disableForm: true });

            var params = new URLSearchParams();
            params.append('usuario', this.state.usuario);
            
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/recovery`, params);
            if (response.status === 200) {
                this.setState({ usuario: '' });

                new Noty({
                    text: `Se ha enviado una nueva contraseña al email <strong>${this.state.usuario}</strong>`,
                    layout: 'top',
                    theme: 'bootstrap-v4',
                    type: 'success',
                    timeout: 5000
                }).show();
            }
        } catch (e) {
            new Noty({
                text: 'El usuario ingresado es inválido',
                layout: 'top',
                theme: 'bootstrap-v4',
                type: 'error',
                timeout: 5000
            }).show();
        }
        finally {
            this.setState({ disableForm: false });
        }
    }

    render() {

        const disabled = this.state.disableForm ? true : false;

        return(
            <div id="page-container">
                <main id="main-container">
                    <div className="hero-static d-flex align-items-center">
                        <div className="w-100">
                            <div className="content content-full bg-white">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col-lg-6 col-xl-4 py-4">
                                        <div className="text-center">
                                            <img src={Logo} style={{ width: '170px', margin: '0 auto' }} alt="Kastor" />
                                            <h2 className="h4 mb-4 mt-4">
                                                Recuperar Acceso File Explorer
                                            </h2>
                                            <h4 className="h6 font-w400 text-muted mb-3">
                                                Por favor ingresa tu cuenta de usuario para enviarte una nueva contraseña
                                            </h4>
                                        </div>
                                        <form className="js-validation-signin" onSubmit={this.handleSubmit}>
                                            <div className="py-3">
                                                <div className="form-group">
                                                    <input type="text" className="form-control form-control-lg" value={this.state.usuario} onChange={this.handleChange} name="usuario" placeholder="Ingresar Usuario" disabled={disabled} />
                                                </div>
                                            </div>
                                            <div className="form-group row justify-content-center mb-0">
                                                <div className="col-md-12 col-xl-6 text-center">
                                                    <button type="button" onClick={this.handleSubmit} className="btn btn-block btn-primary" disabled={disabled}>
                                                        <FaEnvelope /> Recuperar Contraseña
                                                    </button>
                                                    <br />
                                                    <a className="font-size-sm" href="/login" disabled={disabled}>Volver a Login</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="font-size-sm text-center text-muted py-3">
                                <strong>Kastor File Explorer</strong> &copy; <span data-toggle="year-copy"></span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
export default Recovery;