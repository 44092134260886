import React from 'react';
import { FaFileExcel } from 'react-icons/fa';
import Moment from 'react-moment';
import axios from 'axios';

import TiposDocumento from './TiposDocumento';

class Resultado extends React.Component {


    setForKastor(doc) {
        /*
        const url = 'https://dskastor.kastor.cl/facturas/KASTOR/download.php?' + doc.url_kastor;
        return(
            <a style={{cursor: 'pointer'}} onClick={() => window.open(url)}><span className="text-success"><FaFileExcel /></span></a>
        )*/
        if (doc.existe_pdf) {
            return  <button className="btn btn-sm btn-secondary-outline" onClick={() => this.downloadPdfKastor(doc)}>
                        <span className="text-success"><FaFileExcel /></span>
                    </button>            
        } 
        else
        {
            return ('')
        }
    }
    
    setForCaleu(doc) {
        if (doc.existe_pdf) {
            return  <button className="btn btn-sm btn-secondary-outline" onClick={() => this.downloadPdfCaleu(doc)}>
                        <span className="text-success"><FaFileExcel /></span>
                    </button>            
        } 
        else
        {
            return ('')
        }
    }

    setEnlacePdf = (doc) => {
        const empresa = localStorage.getItem('user_emp');        
        return empresa === 'CALEU' ? this.setForCaleu(doc) : this.setForKastor(doc);
    }

    downloadPdfCaleu(doc) {
        const url = `${process.env.REACT_APP_API_URL}/api/documento/archivo/${doc.periodo}/${doc.folionum}/${doc.docnum}`;
        axios.post(url, null, { responseType: 'arraybuffer'})
            .then(response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `Doc Caleu (${doc.folionum}).pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => console.log(error));            
    }

    downloadPdfKastor(doc) {
        const url = `${process.env.REACT_APP_API_URL}/api/documento/archivo/${doc.periodo}/${doc.folionum}/${doc.docnum}`;
        axios.post(url, null, { responseType: 'arraybuffer'})
            .then(response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `Doc Kastor (${doc.folionum}).pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => console.log(error));            
    }

    render() {
        return(
            <div className="table-responsive mt-2">
                <table id="tbl-docs" className="table table-sm table-vcenter">
                    <thead className="thead-dark">
                        <tr>
                            <th>#</th>
                            <th>Fecha</th>
                            <th>Rut</th>
                            <th>Comunidad</th>
                            <th>Folio</th>
                            <th>Tipo Doc</th>
                            <th>Monto</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.props.docs.length > 0 ?
                    (this.props.docs.map((doc) => {
                        if (doc.folionum !== null && doc.folionum !== 0) {
                            const tipoDoc =  TiposDocumento(parseInt(doc.indicator));
                            return (
                            <tr key={doc.docnum}>
                                <td>{doc.docnum}</td>
                                <td><Moment format="DD/MM/YYYY">{doc.docdate}</Moment></td>
                                <td>{doc.cardcode}</td>
                                <td>{doc.cardname}</td>
                                <td>{doc.folionum}</td>
                                <td>{tipoDoc.descripcion}</td>
                                <td>
                                {new Intl.NumberFormat('es-CL', { 
                                    style: 'currency', 
                                    currency: 'CLP',
                                    minimumFractionDigits: 0, 
                                    maximumFractionDigits: 0 
                                }).format(doc.doctotal)}
                                </td>
                                <td>{doc.paidtodate < doc.doctotal ? <span className="text-danger">Pendiente</span> : <span className="text-success">Pagado</span> }</td>
                                <td>{this.setEnlacePdf(doc)}</td>
                            </tr>);
                        }
                    }))
                    :
                    (<tr><td colSpan={9} className="text-center">No hay resultados para mostrar</td></tr>)}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Resultado;